import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks";
import { useFetch } from "../../../../hooks";
import { BASE_URL } from "../../../../utils/Constans";
import { getAuthHeaders } from "../../../../utils/Generic";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../locales";
import axios from "axios";
import ListExamTime from "../../TeacherScreens/ExamScreens/ListExam/Components/ListExamTime";
import { Table, Pagination } from "react-bootstrap";
import { EyeIcon } from "../../../../assets/icons";
import AssetModal from "./AssetModal";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../EnglishExams/CobeExam/firebase";

const ViewCobeResult = () => {
  const { key, uid } = useParams();
  const { t } = useTranslation();
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);

  const currentExam = studentExams?.find((exam) => exam?.key === key) as any;

  // console.log(currentExam);

  const [studentExam, setStudentExam] = useState<any>();
  const [pages, setPages] = useState(0);
  const [studentAnswers, setStudentAnswers] = useState<any>();
  const [currentPage, setCurrentPage] = useState(-1);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [student, setStudentData] = useState<any>();

  const rowsPerPage = 4;

  useEffect(() => {
    setPages(Math.ceil(studentAnswers?.length / rowsPerPage));
    if (studentAnswers?.length > 0) {
      setCurrentPage(0);
    }
  }, [studentAnswers]);

  const onPageChanged = (page: number) => {
    setStudentExam(
      studentAnswers.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
  };

  const handlePrevPage = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      onPageChanged(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < pages) {
      setCurrentPage(currentPage + 1);
      onPageChanged(currentPage + 1);
    }
  };

  const [studentData, studentLoading, studentError] = useFetch(
    `${BASE_URL}/v2/GetUserInfo`,
    { username: uid, accountType: "Student" },
    getAuthHeaders,
    "POST"
  );

  useEffect(() => {
    // console.log(studentData);
    if (!studentLoading && studentData && studentData.userInfo) {
      setStudentData(studentData.userInfo);
    }
  }, [studentData, studentLoading, studentError]);

  const prepareExamAnswers = async (res: any) => {
    setStudentExam(res.data.answer);
    const answersByQuestion = res.data.answer?.answers.reduce(
      (acc: any, answer: any) => {
        const existingAnswer = acc.find(
          (item: any) => item.questionId === answer.questionId
        );

        if (existingAnswer) {
          existingAnswer.answers.push(answer);
        } else {
          acc.push({
            questionId: answer.questionId,
            answers: [answer],
          });
        }

        return acc;
      },
      []
    );

    const _answersByQuestion = [];
    for (let i = 0; i < answersByQuestion.length; i++) {
      const _answer = { ...answersByQuestion[i] };
      console.log(_answer);
      if (_answer.answers[0].answerType === "Record") {
        const storageRef = ref(storage, _answer.answers[0].answer);
        _answer.answers[0].answer = await getDownloadURL(storageRef);
      }

      _answersByQuestion.push(_answer);
    }
    setStudentAnswers(_answersByQuestion);
  };

  useEffect(() => {
    if (student) {
      const getStudentData = async () => {
        const headers = await getAuthHeaders();
        const params = {
          studentId: student.key,
          examId: key,
        };

        axios
          .get(`${BASE_URL}/GetStudentAnswer`, {
            params: { params: JSON.stringify(params) },
            headers: headers.headers,
          })
          .then((res) => {
            prepareExamAnswers(res);
          })
          .catch((err) => console.log(err));
      };

      getStudentData();
    }
  }, [studentData]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className="bg-[#ffffff] md:w-full h-full w-[20rem] mx-4 md-mx-0">
      {studentExam && (
        <div className="bg-[#ffffff]">
          <p className="bg-[#f3f3f3] p-2 w-fit mx-4 my-4 text-[1.3rem]">
            {currentExam?.title}
          </p>

          <div className="mx-10 cursor-pointer" onClick={handleShowModal}>
            <EyeIcon color="#accfe9" />
          </div>

          <div className="md:flex md:flex-row md:justify-between md:mt-10 mx-10 shadow md:px-10 py-2 flex flex-col">
            <div className="flex gap-2 mx-10 md:mx-0">
              <p className="inline">Number of questions: </p>
              <h5> {currentExam?.questions?.length}</h5>
            </div>
            <div className=" bg-[#c9e4ff] px-10 mx-10 md:pt-4 md:-mt-16 rounded md:flex md:flex-col  flex justify-center md:items-center gap-4 md:gap-0 pt-2 ">
              <p className="inline"> Degree </p>
              <h5>{studentExam?.marks}</h5>
            </div>
            <div>
              <ListExamTime examDate={studentExam?.submissionTime} />{" "}
            </div>
          </div>
          {showModal && (
            <AssetModal setShowModal={setShowModal} exam={currentExam} />
          )}
          <div className="md:mx-10">
            <Table
              dir={isRTL() ? "rtl" : "ltr"}
              className="overflow-hidden  shadow md:px-10 py-2"
            >
              <thead>
                <tr>
                  <th>
                    <div className="flex justify-center  md:text-[1.3rem]  ">
                      {t("Question")}
                    </div>
                  </th>
                  <th>
                    <div className="flex justify-center  md:text-[1.3rem]">
                      {t("Your Answer")}
                    </div>
                  </th>

                  <th>
                    <div className="flex justify-center  md:text-[1.3rem]">
                      {t("Comment")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentAnswers?.map((answer: any, index: number) => (
                  <tr key={index.toString()}>
                    <td>
                      <div className="flex  items-center text-[0.8rem] ">
                        <p className="my-4">
                          {currentExam?.questions[index]?.question}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col items-center text-[0.8rem] h-[8vh]">
                        {currentExam?.questions[index]?.answerType ===
                          "Written" && (
                          <p className="my-4">{answer.answers[0].answer}</p>
                        )}
                        {currentExam?.questions[index]?.answerType ===
                          "Record" && (
                          <div className="md:flex md:justify-center md:items-center">
                            <audio
                              src={answer.answers[0].answer}
                              controls
                              className="my-2 w-[10rem] md:w-[20rem]"
                            />
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div className="flex justify-center items-center text-[0.8rem] h-[8vh]">
                        {answer.answers[0].comment || ""}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="flex justify-end">
              <Pagination.Prev onClick={handlePrevPage} />

              <Pagination.Item>
                <span>
                  {currentPage + 1} / {pages}
                </span>
              </Pagination.Item>

              <Pagination.Next onClick={handleNextPage} />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCobeResult;
