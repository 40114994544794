import { Dispatch } from "redux";

import { User } from "../types";
import { getAuthHeaders, logout, siginInWithToken } from "../utils/Generic";
import { ActionAuth } from "../reducers/AuthReducer";
import { BASE_URL } from "../utils/Constans";
import { ActionAuthType } from "./types";
import { ApiRequest } from "../utils/ApiRequests";

const GET_USER_URL = `${BASE_URL}/v2/GetUserInfo`;
const POST_LOGIN_USER = `${BASE_URL}/login`;

export const loginUser = (username: string, password: string) => {
  return async (dispatch: Dispatch<ActionAuth>) => {
    try {
      if (username.trim() === "" || password.trim() === "") {
        dispatch({
          type: ActionAuthType.LOGIN_FAIL,
          payload: "INCORRECT_LOGIN_INFO_ERROR",
        });
        return;
      }
      dispatch({ type: ActionAuthType.FETCH_USER });
      const response = await ApiRequest(
        POST_LOGIN_USER,
        {
          username,
          password,
        },
        "POST"
      );
      const token = response.data.token;
      localStorage.setItem("token", token);
      await siginInWithToken(token);
      dispatch({
        type: ActionAuthType.LOGIN_SUCCESS,
        payload: response.data.userInfo,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ActionAuthType.LOGIN_FAIL,
        payload: "INCORRECT_LOGIN_INFO_ERROR",
      });
    }
  };
};

export const logoutUser = () => {
  localStorage.removeItem("token");
  return async (dispatch: Dispatch<ActionAuth>) => {
    await logout();
    dispatch({
      type: ActionAuthType.LOGOUT,
    });
  };
};

export const syncUser = (username: string) => {
  return async (dispatch: Dispatch<ActionAuth>) => {
    try {
      if (!username) throw "User not exit!";
      const accountType = username.toLowerCase().startsWith("t")
        ? "Teacher"
        : "Student";
      dispatch({ type: ActionAuthType.SYNC_USER });

      const token = localStorage.getItem("token");
      if (!token) throw "Token not found!";
      await siginInWithToken(token);
      const config = await getAuthHeaders();
      const response = await ApiRequest(
        GET_USER_URL,
        {
          username,
          accountType,
        },
        "POST",
        config
      );
      const user: User = response.data.userInfo;
      dispatch({
        type: ActionAuthType.LOGIN_SUCCESS,
        payload: user,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ActionAuthType.LOGOUT,
      });
    }
  };
};

export const setErrorMsg = (msg: string) => {
  return (dispatch: Dispatch<ActionAuth>) => {
    dispatch({
      type: ActionAuthType.SET_ERROR_MSG,
      payload: msg,
    });
  };
};
