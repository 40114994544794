import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks";
import { useFetch } from "../../../../hooks";
import { BASE_URL } from "../../../../utils/Constans";
import { getAuthHeaders } from "../../../../utils/Generic";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../locales";
import axios from "axios";
import ListExamTime from "../../TeacherScreens/ExamScreens/ListExam/Components/ListExamTime";

const ViewWrittenResult = () => {
  const { key, uid } = useParams();
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);

  const currentExam = studentExams?.find((exam) => exam?.key === key) as any;

  // console.log(currentExam);

  const [studentExam, setStudentExam] = useState<any>();

  const [student, setStudentData] = useState<any>();

  const [studentData, studentLoading, studentError] = useFetch(
    `${BASE_URL}/v2/GetUserInfo`,
    { username: uid, accountType: "Student" },
    getAuthHeaders,
    "POST"
  );

  useEffect(() => {
    // console.log(studentData);
    if (!studentLoading && studentData && studentData.userInfo) {
      setStudentData(studentData.userInfo);
    }
  }, [studentData, studentLoading, studentError]);
  useEffect(() => {
    if (student) {
      const getStudentData = async () => {
        const headers = await getAuthHeaders();
        const params = {
          studentId: student.key,
          examId: key,
        };

        axios
          .get(`${BASE_URL}/GetStudentAnswer`, {
            params: { params: JSON.stringify(params) },
            headers: headers.headers,
          })
          .then((res) => {
            setStudentExam(res.data.answer);
          })
          .catch((err) => console.log(err));
      };

      getStudentData();
    }
  }, [studentData]);

  return (
    <div className="bg-[#ffffff] w-full h-full">
      {studentExam && (
        <div className="bg-[#ffffff]">
          <p className="bg-[#f3f3f3] p-2 w-fit mx-4 my-4 text-[1.3rem]">
            {currentExam?.title}
          </p>

          <div className="md:flex md:flex-row md:justify-between md:mt-10 mx-10 shadow md:px-10 gap-2 md:gap-0 py-2 flex flex-col">
            {/* <div className="flex gap-2 mx-10 md:mx-0">
							<p className="inline">Number of questions: </p>
							<h5> {currentExam?.questions?.length}</h5>
						</div> */}
            <div className=" bg-[#c9e4ff] px-10 mx-10 md:pt-4 md:-mt-16 rounded md:flex md:flex-col  flex justify-center md:items-center gap-4 md:gap-0 pt-2 ">
              <p className="inline"> Degree </p>
              <h5>{studentExam?.marks}</h5>
            </div>
            <div>
              <ListExamTime examDate={studentExam?.submissionTime} />{" "}
            </div>
          </div>

          <div className=" mt-4 mx-10 shadow px-10 py-4">
            <div>
              <h5>Student Answer : </h5>
              {studentExam.answers[0].answer}
            </div>
            <div className="my-4">
              <h5>Comment : </h5>
              {studentExam.answers[0]?.comment!}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewWrittenResult;
