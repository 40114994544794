import React, { useEffect } from "react";
import { Group, Student } from "../../../../../../types";
import { BASE_URL } from "../../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../../utils/Generic";
import { useTypedSelector } from "../../../../../../hooks";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { TiDelete } from "react-icons/ti";
import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
interface GradeTableProps {
  group: Group;
  setStudent: any;
  setExamData?: any;
  studentExams: any[];
  setStudentExams: React.Dispatch<React.SetStateAction<any[]>>;
}

const GradeTable = ({
  group,
  setStudent,
  setExamData,
  studentExams,
  setStudentExams,
}: GradeTableProps) => {
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { key } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = React.useState<
    Student | undefined
  >(undefined);

  useEffect(() => {
    const getStudentAnswers = async () => {
      setLoading(true);

      const examStudents = await Promise.all(
        group.students.map(async (student) => {
          const headers = await getAuthHeaders();
          const { data } = await axios.post(
            `${BASE_URL}/v2/GetUserInfo`,
            {
              username: student.uid,
              accountType: "Student",
            },
            { headers: headers.headers }
          );
          const params = {
            examId: key,
            studentId: data.userInfo.key,
            teacherId: user?.key,
          };
          try {
            const examData = await axios.get(`${BASE_URL}/GetStudentExam`, {
              params: { params: JSON.stringify(params) },
              headers: headers.headers,
            });
            let marks: number = 0;

            if (examData?.data?.exam.marks) {
              marks = examData?.data?.exam.marks;
            }
            return {
              name: data.userInfo.name,
              marks: examData?.data?.exam.marks,
              uid: data.userInfo.uid,
              isExam: examData?.data?.exam.status === "Complete" ? true : false,
            };
          } catch (err) {
            console.error(err);
            return {
              name: data.userInfo.name,
              marks: undefined,
              uid: data.userInfo.uid,
              isExam: false,
            };
          }
        })
      );

      setStudentExams(examStudents);
      setExamData(examStudents);
      setLoading(false);
    };

    getStudentAnswers();
  }, [group]);

  return (
    <div className="my-2">
      <table>
        <thead>
          <tr className="text-[1rem]">
            <th>{t("Name")}</th>
            <th className=" px-6">{t("Grade")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td>
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {!loading &&
            studentExams.map((student) => {
              return (
                <tr
                  key={student.uid}
                  className={`cursor-pointer ${
                    selectedStudent?.uid === student.uid ? "bg-[#fdf1e0]" : ""
                  } hover:bg-[#F2F3F5] `}
                  onClick={() => {
                    setSelectedStudent(student);
                    setStudent(student);
                  }}
                >
                  <td className=" py-3">{student.name}</td>
                  <td className=" px-10">
                    {student.marks == undefined
                      ? "-"
                      : Math.round(student.marks)}
                  </td>
                  <td>
                    {student.isExam ? (
                      <div>
                        <AiFillCheckCircle color="#67c06b" size={25} />
                      </div>
                    ) : (
                      <div>
                        <TiDelete color="#ff6868" size={25} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default GradeTable;
