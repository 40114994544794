import React from "react";
import { Modal } from "react-bootstrap";
import { CHANGE_PASSWORD } from "../../../../../assets/images";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../utils/Generic";
import { useTypedSelector, useActions } from "../../../../../hooks";
import axios from "axios";

const UPDATE_PASSWORD = `${BASE_URL}/v2/UpdateUserPassword`;

interface ChangePasswordProps {
  setViewShowModel: (newType: boolean) => void;
}

const ChangePassword = ({ setViewShowModel }: ChangePasswordProps) => {
  const [newPassword, setNewPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");

  const { MsgActions } = useActions();

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const handleSave = async () => {
    const headers = await getAuthHeaders();
    if (newPassword !== verifyPassword) {
      MsgActions.showMsg("Passwords do not match", "danger");
      return;
    }

    axios
      .post(
        UPDATE_PASSWORD,
        { uid: user?.uid, password: newPassword },
        {
          headers: headers.headers,
        }
      )
      .then((res) => {
        MsgActions.showMsg("Password updated successfully", "success");

        setViewShowModel(false);
      })
      .catch((err) => {
        MsgActions.showMsg("Error updating password", "danger");
      });
  };

  return (
    <div>
      <Modal show={true}>
        <Modal.Header
          closeButton
          onClick={() => setViewShowModel(false)}
        ></Modal.Header>
        <Modal.Title className="mx-5 mt-2">
          <h3 className="text-[#18608C]">Change Password</h3>
        </Modal.Title>
        <Modal.Body className=" h-[30rem] overflow-y-scroll">
          <div className="flex flex-wrap justify-center items-center ">
            <img src={CHANGE_PASSWORD} alt="" />
          </div>
          <div className="mt-4 flex flex-col gap-2">
            <div className="flex justify-around items-center">
              <label htmlFor="">New Password</label>
              <input
                type="password"
                className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12 pr-3 w-[12rem]"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="flex justify-around items-center">
              <label htmlFor="">Verify Password</label>
              <input
                type="password"
                className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12 pr-3 w-[12rem]"
                value={verifyPassword}
                onChange={(e) => setVerifyPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-center mt-2">
              <button
                className="bg-[#3188C9] px-1 py-2 text-white w-[10rem] rounded"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangePassword;
